import styled from "styled-components";
import Lottie from "lottie-react";

import palpHero from "../../../../../static/assets/palp_hero03_main_page.svg"

export const IndexHeroImg = styled.img`
  min-height: 80vh;
  max-width: 100%;
  width: auto;
`

export const IndexHeroImgMobile = styled.img`
  width: 100%;
`

export const IndexHeroWrapper = styled.div`
  background: var(--bg-gradient--middle);
`

export const AnimatedCardSection = styled.div`
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
`

export const IndexHeroLotti = styled(Lottie)`
  width: 100%;
  padding-top: 100px;
  height: auto;
`

export const IndexHeroMobileLotti = styled(Lottie)`
  width: 100%;
  padding: 8rem 0 12rem 0;
  height: auto;
`

export const HeroButtonWrapper = styled.div`
  position: absolute;
  margin: 32% 0 0 26%;
  z-index: 2;
  
  animation: fade-in 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  animation-delay: 3s;

  @keyframes fade-in {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const HeroButtonMobileWrapper = styled.div`
  position: absolute;
  margin: 140% 0 0 10%;
  z-index: 2;
  
  animation: fade-in 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  animation-delay: 2s;

  @keyframes fade-in {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const IndexHeroContainer = styled.div`
  background-image: ${() => `url(${palpHero})`};
  min-height: 90vh;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
`