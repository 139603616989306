import styled from "styled-components";
import {Link} from "gatsby";

export const IndexBoxWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1/1 !important;
  box-sizing: border-box;
  justify-self: center;
  margin: auto;
`

export const IndexBoxOutlineWide = styled.div`
  border: 1.25rem solid rgba(255, 255, 255, 0.1);
  border-radius: 3rem;
  padding: 1.8rem;
  display: grid;
  position: relative;
  align-items: stretch;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  z-index: 1;
  box-shadow: inset 0 0 0 0.25rem #29aae1;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  
  /*IMÁDJUK A SAFARI BÖNGÉSZŐT*/
  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) {
      min-height: 300px;
    }
  }
`

export const IndexBoxTitle = styled.h1`
  color: #173f6c;
  background-image: url("/assets/others/h1_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  display: inline-block;
  padding: 2px 2rem 2px 1rem;
  font-size: 1rem;
  line-height: 1.3rem;
  border-top-left-radius: 8px;
  z-index: 2;
  position: relative;
  margin: 0 0 0 10% !important;
  font-weight: 650;
`

export const IndexBoxButton = styled(Link)`
  background-color: #173f6c;
  opacity: 0.5;
  color: #a9c6ea;
  text-align: center;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  z-index: 3;
  display: grid;
  align-self: stretch;
  place-items: center;
  word-wrap: break-word;
  width: 100%;
  height: 100%;

  &:hover {
    opacity: 1;
  }

  ${props => {
    if (props.topleft) {
      return `
        border-top-left-radius: 1rem;
    `
    } else if (props.topright) {
      return `
        border-top-right-radius: 1rem;
    `
    } else if (props.bottomleft) {
      return `
        border-bottom-left-radius: 1rem;
    `
    } else if (props.bottomright) {
      return `
        border-bottom-right-radius: 1rem;
    `
    }
  }}
`

export const IndexBoxCenterButton = styled(Link)`
  display: grid;
  width: 30%;
  height: 30%;
  z-index: 4;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #0b2f5e;
  border: 0.5rem solid white;
  border-radius: 50%;
  color: #29aae1;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;

  &:hover {
    background-color: #173f6c;
  }
`