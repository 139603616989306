import React from "react";

import {
    IndexBoxButton, IndexBoxCenterButton,
    IndexBoxOutlineWide,
    IndexBoxTitle,
    IndexBoxWrapper
} from "../../styled/pages/index/IndexBoxComponents";

const IndexBox = ({
                      title,
                      leftTopText,
                      leftTopURL,
                      rightTopText,
                      rightTopURL,
                      leftBottomText,
                      leftBottomUrl,
                      rightBottomText,
                      rightBottomUrl,
                      centerText,
                      centerURL,
                  }) => {

    return (
        <IndexBoxWrapper>
            <IndexBoxTitle>
                {title}
            </IndexBoxTitle>
            <IndexBoxOutlineWide>
                <IndexBoxButton topleft="true" to={leftTopURL}>
                    {leftTopText}
                </IndexBoxButton>
                <IndexBoxButton topright="true" to={rightTopURL}>
                        {rightTopText}
                </IndexBoxButton>
                <IndexBoxButton bottomleft="true" to={leftBottomUrl}>
                        {leftBottomText}
                </IndexBoxButton>
                <IndexBoxButton bottomright="true" to={rightBottomUrl}>
                        {rightBottomText}
                </IndexBoxButton>
                <IndexBoxCenterButton to={centerURL}>
                        {centerText}
                </IndexBoxCenterButton>
            </IndexBoxOutlineWide>
        </IndexBoxWrapper>
    )
}

export default IndexBox