import React, {useRef} from "react";

import {Body, Header3} from "../styled/typography/Typography";
import {AnimatedCardLottie, AnimatedCardWrapper} from "../styled/cards/AnimatedCardComponents";

const AnimatedCard = ({title, description, lottie, link}) => {
    const lottieRef = useRef();

    function playAnim() {
        lottieRef.current.setSpeed(1.5);
        lottieRef.current.goToAndPlay(0, true);
        lottieRef.current.setDirection(1);
        lottieRef.current.play();
    }

    function stopAnim() {
        lottieRef.current.setSpeed(1.5);
        lottieRef.current.setDirection(-1);
    }

    return (

        <AnimatedCardWrapper
            to={link}
            onMouseEnter={() => playAnim()}
            onMouseLeave={() => stopAnim()}
        >
            {lottie && (
                <AnimatedCardLottie
                    lottieRef={lottieRef}
                    animationData={lottie}
                    autoplay={false}
                    loop={false}
                    onComplete={() => lottieRef.current.goToAndStop(101, true)}
                    onDOMLoaded={() => lottieRef.current.goToAndStop(101, true)}
                />
            )}
            {title &&
            <Header3 color="white" marginBottom="small">
                {title}
            </Header3>
            }
            {description &&
            <Body color="body-text">
                {description}
            </Body>
            }
        </AnimatedCardWrapper>
    );
};

export default AnimatedCard;
