import React from "react";

import {Body, BodyLi, Header3} from "../styled/typography/Typography";
import {
    KeyFeaturesButtonWrapper,
    KeyFeaturesCardImg,
    KeyFeaturesCardWrapper
} from "../styled/cards/KeyFeaturesCardComponents";
import {ButtonLink} from "../styled/button/ButtonComponents";

const KeyFeaturesCard = ({src, title, list, description, button}) => {
    return (
        <KeyFeaturesCardWrapper>
            <KeyFeaturesCardImg alt={title ? title : "avatar"} src={src}/>
            {title && <Header3 textAlign="center" color="white" marginBottom="small">{title}</Header3>}
            {list && description && (
                <>
                    <Body color="body-text" textAlign="center">{description}</Body>
                    <ul>
                        {list.map((item) => {
                            return (
                                <BodyLi key={item.id} marginTop="smaller" color="body-text">
                                    {item.item}
                                </BodyLi>
                            );
                        })}
                    </ul>
                </>
            )}
            {list && !description && (
                <ul>
                    {list.map((item) => {
                        return (
                            <BodyLi key={item.id} marginTop="smaller" color="body-text">
                                {item.item}
                            </BodyLi>
                        );
                    })}
                </ul>
            )}
            {description && !list && (
                <>
                    <Body color="body-text" textAlign="center">{description}</Body>
                </>
            )}
            {button && (
                <KeyFeaturesButtonWrapper>
                    <ButtonLink to={button.link} color="primary-brand">{button.name}</ButtonLink>
                </KeyFeaturesButtonWrapper>
            )}
        </KeyFeaturesCardWrapper>
    );
};

export default KeyFeaturesCard
