import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";
import IndexBox from "../components/pages/index/IndexBox";
import Form from "../components/forms/Form";
import KeyFeatures from "/src/components/pages/products/KeyFeatures";
import AnimatedCard from "/src/components/cards/AnimatedCard";
import HeaderSection from "../components/sections/HeaderSection";

import {LottieAnimation} from "../components/styled/lotti/LottieAnimations";
import {Body, Header2, Span, Title} from "../components/styled/typography/Typography";
import Col from "../components/styled/grid/Col";
import {ButtonContainer, ButtonLink} from "../components/styled/button/ButtonComponents";
import {OnlyDesktop, OnlyMobile} from "../components/styled/views/MobileOrDesktop";
import {
    AnimatedCardSection,
} from "../components/styled/pages/index/IndexComponents";

import networkAnim from "/static/assets/animations/icon_animations/white/networkSecurity.json";
import apiAnim from "/static/assets/animations/icon_animations/white/apiSecurity.json";
import privilegedAnim from "/static/assets/animations/icon_animations/white/privilegedAccess.json";
import adAnim from "/static/assets/animations/icon_animations/white/adSecurity.json";
import identityAnim from "/static/assets/animations/icon_animations/white/identityGovernance.json";
import zeroTrustAnim from "/static/assets/animations/white/zero_trust_anim.json";
import blogAnim from "/static/assets/animations/icon_animations/white/blog.json";
import videoAnim from "/static/assets/animations/icon_animations/white/support.json";
import circuit from "/static/assets/animations/circuit/circuit01.json";

const Index = ({data}) => {

    const {index_page, contact} = data;

    return (
        <Layout lang={index_page.lang} seo={index_page.SEO}>
            <HeaderSection
                image={index_page.hero_section.illustration.url}
                subtitle={index_page.hero_section.subtitle}
                title_color={index_page.hero_section.title_color}
                title_white={index_page.hero_section.title_white}
                button={index_page.hero_section.button}
                index
            />
            <SectionLayoutGrid customBackground="middle" id={"Solutions"}>
                <Col span={6}>
                    <OnlyDesktop>
                        <IndexBox
                            title={index_page.lang === "hu" ? "PROFESSZIONÁLIS SZOLGÁLTATÁSOK" : "PROFESSIONAL SERVICES"}
                            centerText={"Balasys"}
                            centerURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/about-us/"}
                            rightTopText={index_page.lang === "hu" ? "API-biztonság" : "API Security"}
                            rightTopURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/solutions/api-security/"}
                            leftTopText={index_page.lang === "hu" ? "Hálózatbiztonság" : "Network Security"}
                            leftTopURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/solutions/network-security/"}
                            rightBottomText={index_page.lang === "hu" ? "IT menedzsment" : "IT Management"}
                            rightBottomUrl={(index_page.lang === "hu" ? "/hu" : "/en") + "/quest-products#CHANGE AUDITOR"}
                            leftBottomText={index_page.lang === "hu" ? "Jogosultságkezelés" : "Identity and Access Management"}
                            leftBottomUrl={(index_page.lang === "hu" ? "/hu" : "/en") + "/solutions/identity-governance/"}
                        />
                    </OnlyDesktop>
                </Col>
                <Col span={6} grid center>
                    <Title color="white" marginBottom="normal" storybook>
                        {index_page.solutions.title_white}<br/>
                        <Span color="primary-brand">{index_page.solutions.title_color}</Span>
                    </Title>
                    <Body color="body-text">
                        {parse(index_page.solutions.description)}
                    </Body>
                </Col>
                <Col span={6}>
                    <OnlyMobile>
                        <IndexBox
                            title={index_page.lang === "hu" ? "PROFESSZIONÁLIS SZOLGÁLTATÁSOK" : "PROFESSIONAL SERVICES"}
                            centerText={"Balasys"}
                            centerURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/about-us/"}
                            rightTopText={index_page.lang === "hu" ? "API-biztonság" : "API Security"}
                            rightTopURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/solutions/api-security/"}
                            leftTopText={index_page.lang === "hu" ? "Hálózatbiztonság" : "Network Security"}
                            leftTopURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/solutions/network-security/"}
                            rightBottomText={index_page.lang === "hu" ? "IT menedzsment" : "IT Management"}
                            rightBottomUrl={(index_page.lang === "hu" ? "/hu" : "/en") + "/quest-products#CHANGE AUDITOR"}
                            leftBottomText={index_page.lang === "hu" ? "Jogosultságkezelés" : "Identity and Access Management"}
                            leftBottomUrl={(index_page.lang === "hu" ? "/hu" : "/en") + "/solutions/identity-governance/"}
                        />
                    </OnlyMobile>
                </Col>
            </SectionLayoutGrid>
            <LottieAnimation animationData={circuit} left="true"/>
            <KeyFeatures
                big_title={index_page.title_products}
                sub_title={index_page.subtitle_products}
                cards={index_page.products}
            />
            <SectionLayoutGrid id={"Solutions1"}>
                <Col>
                    <Header2 color="white" textAlign="center">
                        {index_page.title_solutions}
                    </Header2>
                </Col>
                <Col>
                    <AnimatedCardSection>
                        <AnimatedCard
                            description={index_page.solutions_card[0].description}
                            link={index_page.solutions_card[0].link}
                            title={index_page.solutions_card[0].title}
                            lottie={networkAnim}
                        />
                        <AnimatedCard
                            description={index_page.solutions_card[1].description}
                            link={index_page.solutions_card[1].link}
                            title={index_page.solutions_card[1].title}
                            lottie={zeroTrustAnim}
                        />
                        <AnimatedCard
                            description={index_page.solutions_card[2].description}
                            link={index_page.solutions_card[2].link}
                            title={index_page.solutions_card[2].title}
                            lottie={apiAnim}
                        />
                        <AnimatedCard
                            description={index_page.solutions_card[3].description}
                            link={index_page.solutions_card[3].link}
                            title={index_page.solutions_card[3].title}
                            lottie={identityAnim}
                        />
                        <AnimatedCard
                            description={index_page.solutions_card[4].description}
                            link={index_page.solutions_card[4].link}
                            title={index_page.solutions_card[4].title}
                            lottie={adAnim}
                        />
                        <AnimatedCard
                            description={index_page.solutions_card[5].description}
                            link={index_page.solutions_card[5].link}
                            title={index_page.solutions_card[5].title}
                            lottie={privilegedAnim}
                        />
                    </AnimatedCardSection>
                </Col>
            </SectionLayoutGrid>
            <SectionLayoutGrid id={"Services"}>
                <Col span={6} grid center>
                    <Title color="white" marginBottom="normal">
                        {index_page.services.title_white}
                    </Title>
                    <Body color="body-text">
                        {index_page.services.description}
                    </Body>
                    <Col>
                        <ButtonContainer>
                            <ButtonLink to={index_page.services.button.link} color="primary-brand">
                                {index_page.services.button.name}
                            </ButtonLink>
                        </ButtonContainer>
                    </Col>
                </Col>
                <Col span={6}>
                    <IndexBox
                        title={index_page.lang === "hu" ? "PROFESSZIONÁLIS SZOLGÁLTATÁSOK" : "PROFESSIONAL SERVICES"}
                        centerText={index_page.lang === "hu" ? "Kompetencia központ" : "Balasys"}
                        centerURL={index_page.lang === "hu" ? "/hu/services#competence" : "/en/about-us/"}
                        rightTopText={index_page.lang === "hu" ? "Tanácsadás" : "Consultancy"}
                        rightTopURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/services#consultancy"}
                        leftTopText={index_page.lang === "hu" ? "Bevezetés" : "Implementation"}
                        leftTopURL={(index_page.lang === "hu" ? "/hu" : "/en") + "/services#implementation"}
                        rightBottomText={index_page.lang === "hu" ? "Támogatás" : "Technical support"}
                        rightBottomUrl={(index_page.lang === "hu" ? "/hu" : "/en") + "/services#support"}
                        leftBottomText={index_page.lang === "hu" ? "Tréningek" : "Competence center"}
                        leftBottomUrl={index_page.lang === "hu" ? "/hu/services#training" : "/en/services#competence"}
                    />
                </Col>
            </SectionLayoutGrid>
            <SectionLayoutGrid id={"Resources"}>
                <Col>
                    <Header2 color="white" textAlign="center" marginBottom="small">
                        {index_page.lang === "hu" ? "Hasznos információk" : "Resources"}
                    </Header2>
                </Col>
                <Col span={4}>
                    <AnimatedCard
                        link={(index_page.lang === "hu" ? "/hu" : "/en") + "/blogs/"}
                        title={"Blog"}
                        lottie={blogAnim}
                    />
                </Col>
                <Col span={4}>
                    <AnimatedCard
                        link={(index_page.lang === "hu" ? "/hu" : "/en") + "/content-library/"}
                        title={index_page.lang === "hu" ? "Tudástár" : "Content Library"}
                        lottie={identityAnim}
                    />
                </Col>
                <Col span={4}>
                    <AnimatedCard
                        link={(index_page.lang === "hu" ? "/hu" : "/en") + "/press/"}
                        title={index_page.lang === "hu" ? "Sajtó" : "Press"}
                        lottie={videoAnim}
                    />
                </Col>
                <Col>
                    <Form form={contact} lang={index_page.lang} isContact={true}/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleIndexPage($locale: String) {
         index_page: strapiIndexPages(lang: { eq: $locale }) {
          lang
          hero_section {
              title_white
              title_color
              subtitle
              id
              button {
                name
                link
              }
              illustration {
                url
              }
            }
          solutions {
            title_white
            title_color
            description
          }
          title_products
          subtitle_products
          products {
            id
            avatar {
              url
            }
            description
            button {
              link
              name
            }
          }
          title_solutions
          solutions_card {
            id
            link
            title
            description
          }
          services {
            title_white
            description
            button {
              link
              name
            }
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
      contact: strapiContactUsForms(lang: { eq: $locale }) {
          Form {
            country
            email
            first_name
            help_dropdown
            id
            last_name
            message
            phone
            preffered_dropdown
          }
      }
   }
 `

export default Index
