import {Link} from "gatsby";
import styled from "styled-components";
import Lottie from "lottie-react";

export const AnimatedCardWrapper = styled(Link)`
  display: grid;
  grid-template-columns: 1fr !important;
  grid-template-rows: 100px 40px auto;
  align-content: start;
  grid-gap: 1.25rem;
  background-color: #173f6c;
  border-radius: 3px;
  transition: box-shadow 500ms;
  text-align: center;
  padding: 1.25rem;

  &:hover {
    box-shadow: 0px 0px 19px 0px #4fa0e2;
  }

  @media screen and (max-width: 800px) {
    grid-template-rows: 75px 25px auto;
  }
`

export const AnimatedCardLottie = styled(Lottie)`
  max-width: 100px;
  max-height: 100px;
  place-self: center;

  @media screen and (max-width: 800px) {
    max-width: 75px;
    max-height: 75px;
  }
`