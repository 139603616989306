import React from "react";

import KeyFeaturesCard from "../../cards/KeyFeaturesCard";
import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";

import Col from "../../styled/grid/Col";
import {Body, Header2} from "../../styled/typography/Typography";
import {OnlyDesktop, OnlyMobile} from "../../styled/views/MobileOrDesktop";
import {ColumnContainer} from "../../styled/pages/products/key-features/KeyFeaturesComponents";

const KeyFeatures = ({big_title, sub_title, cards}) => {
    return (
        <SectionLayoutGrid id={"key-features"}>
            <Col>
                <OnlyDesktop>
                    <Header2 color="white" textAlign="center">{big_title}</Header2>
                    {sub_title && <Body color="body-text" textAlign="center">{sub_title}</Body>}
                </OnlyDesktop>
                <OnlyMobile>
                    <Header2 color="white" textAlign="left">{big_title}</Header2>
                    {sub_title && <Body color="body-text" textAlign="left">{sub_title}</Body>}
                </OnlyMobile>
            </Col>
            <ColumnContainer>
                {cards.map((item) => {
                    return (
                        <KeyFeaturesCard
                            key={item.id}
                            src={item.avatar.url}
                            title={item.title}
                            list={item.list}
                            description={item.description}
                            button={item.button}
                        />
                    );
                })}
            </ColumnContainer>
        </SectionLayoutGrid>
    );
};

export default KeyFeatures;
